import React, { useState, useEffect, useRef } from 'react';
import MyFyeIcon from '../assets/myFyeLogo.png';
import FinFriendIcon from '../assets/FinFriendIcon.png';
import buci from '../assets/buci.jpg';

export default function CompanySelector() {
  const refOne = useRef<HTMLDivElement>(null);
  const refTwo = useRef<HTMLDivElement>(null);
  const refThree = useRef<HTMLDivElement>(null);
  const refFour = useRef<HTMLDivElement>(null);
  const isSmallScreen = window.innerWidth <= 768;
  const isReallyBigScreen = window.innerWidth >= 1100;

  type BlurbKey = 'one' | 'two' | 'three' | 'four';

  const [blurbHeight, setBlurbHeight] = useState({
    one: 0,
    two: 0,
    three: 0,
    four: 0,
  });

const toggleBlurb = (blurb: BlurbKey) => {
    // Toggle the clicked blurb and set all others to 0 (hidden)
    const updatedHeights = {
      one: 0,
      two: 0,
      three: 0,
      four: 0,
    };

    const refMap = {
      one: refOne,
      two: refTwo,
      three: refThree,
      four: refFour,
    };

    const refCurrent = refMap[blurb]?.current;

    if (refCurrent && blurbHeight[blurb] === 0) {
      updatedHeights[blurb] = refCurrent.scrollHeight;
    }

    setBlurbHeight(updatedHeights);
  };
  
  if (isSmallScreen) {

  }

  return (
    <div>


      
      <div style={{display: 'flex', flexDirection: 'column', width: isSmallScreen ? '100vw': isReallyBigScreen ? '60vw' : '80vw', 
      gap: isSmallScreen ? '10px' : '20px', color:'white', fontSize: isSmallScreen ? '30px' : '25px', 
      marginLeft: isSmallScreen ? '0px' : '0px'}}>




<hr style={{ border: 'none', borderTop: '1.5px solid white', margin: '10px 0' }} />
        <div onClick={() => toggleBlurb('one')} style={{display: 'flex', 
          flexDirection: 'row', alignItems: 'center', gap: '30px', cursor: 'pointer'}}>

                            <img
                            src= {buci}
                            alt=""
                            style={{
                              marginLeft: isSmallScreen ? '10px' : '0px',
                              width: isSmallScreen ? '50px' : '100px', // Set the desired width
                              height: 'auto', // Set the desired height
                              borderRadius: '5px',
                            }}></img>
          <div style={{fontWeight: 'bold', fontSize: isSmallScreen ? '25px' : '38px'}}>Blockchain UCI</div>
        </div>
        <div ref={refOne} style={{overflow: 'hidden', transition: 'height 400ms ease', height: `${blurbHeight.one}px`}}>


          <div style={{fontSize: isSmallScreen ? '18px' : '25px', lineHeight: '1.5', paddingLeft: isSmallScreen ? '10px' : '0px',}}>

          <div style={{fontSize: isSmallScreen ? '25px' : '38px'}}>
        Technical Lead<br/>
        </div>

          <a href="https://www.blockchainuci.org/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
  <div style={{ color: 'lightblue', cursor: 'pointer', fontSize: isSmallScreen ? '25px' : '38px' }}>
  BlockchainUCI.org
  </div>
</a>


<br/>
<div>
    <ul>
        <li>Leading research and development teams on long term software projects</li>
    </ul>
    <br/>
</div>
        </div>
        </div>




        <hr style={{ border: 'none', borderTop: '1.5px solid white', margin: '10px 0' }} />
        <div onClick={() => toggleBlurb('two')} style={{display: 'flex', 
          flexDirection: 'row', alignItems: 'center', gap: '30px', cursor: 'pointer'}}>

                            <img
                            src= {MyFyeIcon}
                            alt=""
                            style={{
                              marginLeft: isSmallScreen ? '10px' : '0px',
                              width: isSmallScreen ? '50px' : '100px', // Set the desired width
                              height: 'auto', // Set the desired height
                              borderRadius: '5px',
                            }}></img>
          <div style={{fontWeight: 'bold', fontSize: isSmallScreen ? '25px' : '38px'}}>MyFye</div>
        </div>
        <div ref={refTwo} style={{overflow: 'hidden', transition: 'height 400ms ease', height: `${blurbHeight.two}px`}}>


          <div style={{fontSize: isSmallScreen ? '18px' : '25px', lineHeight: '1.5', paddingLeft: isSmallScreen ? '10px' : '0px',}}>

          <div style={{fontSize: isSmallScreen ? '25px' : '38px'}}>
        Software Engineer<br/>
        </div>

          <a href="https://myfye.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
  <div style={{ color: 'lightblue', cursor: 'pointer', fontSize: isSmallScreen ? '25px' : '38px' }}>
    MyFye.com
  </div>
</a>


<br/>
<div>
    <ul>
        <li>Built a decentralized banking app, secured with users’ mobile phone hardware, capable of holding U.S. Gov. bonds on-chain</li>
        <li>Designed the front end with TypeScript, HTML, CSS and Figma</li>
        <li>Architected complex (10+ minute long) algorithms to adapt to blockchain network congestion, swap failures and high fees</li>
    </ul>
    <br/>
</div>
        </div>
        </div>
                

        <hr style={{ border: 'none', borderTop: '1.5px solid white', margin: '10px 0' }} />
        <div 
                onClick={() => toggleBlurb('three')}
                style={{display: 'flex', 
                flexDirection: 'row', alignItems: 'center', gap: '30px', cursor: 'pointer'
                }}>

                <img
                src= {FinFriendIcon}
                alt=""
                style={{
                  marginLeft: isSmallScreen ? '10px' : '0px',
                  width: isSmallScreen ? '50px' : '100px', // Set the desired width
                  borderRadius: '15px',
                  height: 'auto', // Set the desired height
                }}></img>

                <div style={{fontWeight: 'bold', fontSize: isSmallScreen ? '25px' : '38px'  }}>
                    FinFriend
                </div>
        </div>

            <div
                ref={refThree}
                style={{
                overflow: 'hidden',
                transition: 'height 400ms ease',
                height: `${blurbHeight.three}px`,
                }}
            >
                        <div style={{fontSize: isSmallScreen ? '18px' : '25px',
                        paddingLeft: isSmallScreen ? '10px' : '0px', lineHeight: '1.5'}}>
                          
                          <div style={{fontSize: isSmallScreen ? '25px' : '38px'}}>
        Founder<br/>
        </div>

                          <a href="https://finfriend.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
  <div style={{ color: 'lightblue', cursor: 'pointer', fontSize: isSmallScreen ? '25px' : '38px' }}>
    FinFriend.com
  </div>
  </a>

  <br/>
<div>
    <ul>
        <li>Built a create-your-own-currency platform for iOS, Android and web that has made over $1,000 in revenue</li>
        <li>Built transactions by email with a database and Sendgrid API</li>
        <li>Created an exchange with advanced computer science techniques that has traded over $5,000 in volume</li>
    </ul>
    <br/>
</div>

    </div>
                </div>

                {isSmallScreen && (
              <hr style={{ border: 'none', borderTop: '1.5px solid white', marginTop: '10px', width: '100vw' }} />
              )}
              { !isSmallScreen && (
            <hr style={{ border: 'none', borderTop: '1.5px solid white', margin: '10px 0' }} />
              )}
            </div>




    </div>



  );
}