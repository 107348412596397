import React, { useState, useEffect } from 'react';
import styles from '../../App.module.scss';
import { useNavigate } from 'react-router-dom';
import GavinM from '../assets/GavinM.jpg';
import GavinM2 from '../assets/GavinM2.png';
import appleIcon from '../assets/appleIcon.png';
import typeScriptLogo from '../assets/typescript.png';
import javascriptLogo from '../assets/javascriptLogo.png';
import kotlinLogo from '../assets/kotlinLogo.png';
import swiftLogo from '../assets/swiftLogo.png';
import bitcoinIcon from '../assets/bitcoinIcon.png';
import bitPayLogo from '../assets/bitPayLogo.png';
import blockchainLogo from '../assets/blockchainLogo.png';
import androidLogo from '../assets/androidLogo.png';
import solanaLogo from '../assets/solanaLogo.png';
import metamaskLogo from '../assets/metamaskLogo.png';
import awsLogo from '../assets/awsLogo.svg.png';
import reactLogo from '../assets/reactLogo.png';
import pythonLogo from '../assets/pythonLogo.png';
import cPlusPlusLogo from '../assets/cPlusPlusLogo.svg.png';
import adobeLogo from '../assets/adobeLogo.svg.png';
import CompanySelector from '../components/companySelector';
import Education from '../components/education';
import Contact from '../components/contact';
import instagram from '../assets/instagram.png';
import github from '../assets/github.png';
import linkedin from '../assets/linkedin.webp';

export default function Home() {

    const isSmallScreen = window.innerWidth <= 700;

    const navigate = useNavigate();
    
    const [showToolBeltToolTip, setshowToolBeltToolTip] = useState(false);

    const [fontSize, setFontSize] = useState(1);  // Initial font size
    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        const handleScroll = () => {
            if (isSmallScreen) {
                // Calculate new font size based on scroll position (adjust logic as needed)
                let newFontSize = 1 + window.scrollY * 0.04;  // grows 0.04px for every 1px of scroll
            
                // Ensure font size doesn't exceed 35px
                newFontSize = Math.min(newFontSize, 29);
            
                setFontSize(newFontSize);
            } else {
                // Calculate new font size based on scroll position (adjust logic as needed)
                let newFontSize = 20 + window.scrollY * 0.1;  // grows 0.05px for every 1px of scroll
            
                // Ensure font size doesn't exceed 35px
                newFontSize = Math.min(newFontSize, 55);
            
                setFontSize(newFontSize);
            }

        };
    
        // Add the event listener
        window.addEventListener('scroll', handleScroll);
    
        // Cleanup
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);



  /*
  if (isSmallScreen) {
    return (
    <div>
        
    {<SmallScreenLanding/>}

  </div>
    )
  };

  */



  return (
      
    <div>
        <div style={{ width: '100vw', height: isSmallScreen ? '130vh' : '100vh', backgroundColor: '#222222'}}>
        <div style={{ display: 'flex', 
        flexDirection: isSmallScreen ? 'column' : 'row', 
        justifyContent: 'space-around', paddingTop: '100px', 
        alignItems: 'center', gap: isSmallScreen? '25px' : '0px'}}>

        <div style={{display: 'flex', flexDirection: 'column', gap: '30px', color: 'white',  
        marginLeft: isSmallScreen ? '10px' : '0px',  }}>

        <div style={{  textAlign: 'center', fontSize: isSmallScreen ? '32px' : '55px',  }}>
            Gavin D. Milligan


        </div>

        <div style={{fontSize: isSmallScreen ? '22px' : '40px', textAlign: 'center'}}>

Software Engineer
</div>

        </div>


        <img
            src={GavinM2}
            style={{
                marginTop: isSmallScreen ? '50px' : '0px',
                width: isSmallScreen ? '250px': '30vw', 
                maxWidth: '400px',
                height: 'auto',
                borderRadius: '10%',
                marginRight: isSmallScreen? '0px' : '10px'
            }}
        />
        </div>



        </div>

        <div style={{ width: '100vw', height: '100%', backgroundColor: '#222222', marginTop: isSmallScreen ? '-20px' : '0px'}}>

        <div style={{ color: 'white', fontSize: `${fontSize}px`, textAlign: 'center',
            }}
            onMouseEnter={() => setshowToolBeltToolTip(true)}
            onMouseLeave={() => setshowToolBeltToolTip(false)}>
                <div style={{opacity: 1}}>
      Skills
      </div>
    </div>


<div>

    <div style={{display: 'flex', width: '100vw', justifyContent: 'space-around', 
    marginTop: '40px', alignItems: 'center'}}>


<div style={{ display: 'flex', alignItems: 'center' }}>
    <img
        src={appleIcon}
        alt="Apple Icon"
        style={{
            width: 'auto',
            height: `${fontSize}px`,
        }}
    />
    <span style={{ 
        marginLeft: '10px', 
        fontFamily: '"Helvetica Neue", Arial, sans-serif', 
        color: 'white',
        fontSize: `${fontSize-10}px` 
    }}>
        iOS
    </span>
</div>



<img
            src={typeScriptLogo}
            style={{
                width: 'auto', 
                height: `${fontSize}px`,
            }}
        />

<img
            src={javascriptLogo}
            style={{
                width: 'auto', 
                height: `${fontSize}px`,
            }}
        />

<img
            src={kotlinLogo}
            style={{
                width: 'auto', 
                height: `${fontSize-10}px`,
                background: 'white',
                border: '5px solid white',
                borderRadius: '2px'
            }}
        />
        </div>

        <div style={{display: 'flex', width: '100vw', justifyContent: 'space-around' ,
         marginTop: '40px', alignItems: 'center'}}>
<img
            src={swiftLogo}
            style={{
                width: 'auto', 
                height: isSmallScreen? `${fontSize+10}px` : `${fontSize+10}px`,
                background: 'white',
                borderRadius: isSmallScreen? '5px' : '10px'
                
            }}
        />

<img
            src={bitcoinIcon}
            style={{
                width: 'auto', 
                height: `${fontSize}px`,
            }}
        />

<img
            src={blockchainLogo}
            style={{
                width: 'auto', 
                height: `${fontSize}px`,
                background: 'white',
                borderRadius: '5px'
            }}
        />

<img
            src={bitPayLogo}
            style={{
                width: 'auto', 
                background: 'white',
                borderRadius: '5px',
                height: isSmallScreen ? `${fontSize+5}px` : `${fontSize+6}px`,
            }}
        />
</div>




<div style={{display: 'flex', width: '100vw', justifyContent: 'space-around' ,
         marginTop: '40px', alignItems: 'center'}}>
<img
            src={androidLogo}
            style={{
                width: 'auto', 
                height: `${fontSize}px`,
            }}
        />

<img
            src={solanaLogo}
            style={{
                width: 'auto', 
                height: isSmallScreen ? `${fontSize-10}px` : `${fontSize-25}px`,
            }}
        />

<img
            src={metamaskLogo}
            style={{
                width: 'auto', 
                height: `${fontSize}px`,
            }}
        />

<img
            src={awsLogo}
            style={{
                width: 'auto', 
                background: 'white',
                padding: '5px',
                borderRadius: isSmallScreen ? '5px' : '10px',
                height: `${fontSize}px`,
            }}
        />
</div>







<div style={{display: 'flex', width: '100vw', justifyContent: 'space-around' ,
         marginTop: '40px', alignItems: 'center'}}>
<img
            src={reactLogo}
            style={{
                width: 'auto', 
                height: `${fontSize}px`,
            }}
        />

<img
            src={pythonLogo}
            style={{
                width: 'auto', 
                height: `${fontSize}px`,
            }}
        />

<img
            src={cPlusPlusLogo}
            style={{
                width: 'auto', 
                height: `${fontSize}px`,
            }}
        />

<div style={{ display: 'flex', alignItems: 'center' }}>
    <img
        src={adobeLogo}
        alt="Apple Icon"
        style={{
            width: 'auto',
            height: `${fontSize}px`,
        }}
    />
    <span style={{ 
        marginLeft: '10px', 
        fontFamily: '"Helvetica Neue", Arial, sans-serif', 
        color: 'white',
        fontSize: `${fontSize-10}px` 
    }}>
        Adobe
    </span>
</div>



</div>


        </div>

        <div style={{marginTop: '150px'}}>
            <Education/>
            </div>

        {isSmallScreen ? (
                <>
                


        <div style={{textAlign: 'center', fontSize: '35px', color: 'white'}}>
                Experience
            </div>

        <div style={{paddingRight: window.innerWidth < 1100 ? '40px' : '80px', maxWidth: window.innerWidth < 1100 ? '450px' : '950px',}}>
        <CompanySelector/>
        </div>

                </>
            ) : (
                <>
                
                <div style={{ display: 'flex', justifyContent: 'space-around'}}>


        <div>

            <div style={{textAlign: 'center', fontSize: '40px', color: 'white', gap: '30px'}}>
            Experience
            </div>
            <div style={{paddingRight: window.innerWidth < 1100 ? '40px' : '80px', 
            paddingLeft: '40px',
            maxWidth: window.innerWidth < 1100 ? '450' : '950px',}}>
        <CompanySelector/>
        </div>
        </div>

        </div>

                </>
            )}


<div style={{marginTop: '200px', paddingBottom: '30px'}}>
<Contact/>
</div>
<div style={{display: 'flex',
        paddingBottom: '100px',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'center' : 'flex-start',
        gap: isMobile ? '30px': '120px',
        justifyContent: 'center'}}>
      <a href="https://www.linkedin.com/in/gavin-d-milligan/" target="_blank" rel="noopener noreferrer">
        <img src={linkedin} style={{ width: '40px', height: 'auto', cursor: 'pointer' }} />
      </a>

      <a href="https://github.com/hashedonemilliontimes" target="_blank" rel="noopener noreferrer">
        <img src={github} style={{ width: '40px', height: 'auto', filter: 'invert(1)' }} />
      </a>

      <a href="https://www.instagram.com/gavmilligan/?hl=en" target="_blank" rel="noopener noreferrer">
        <img src={instagram} style={{ width: '40px', height: 'auto' }} />
      </a>
    </div>

        </div>
        

    </div>
  );
}